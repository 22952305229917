<template>
  <a-card>
    <a-row>
      <a-col span="20"></a-col>
      <a-col span="4">
        <exc-upload
            @handleUrl="handleUrl(arguments)"
            :upload-text="'上传'"
            :is-disabled="false"
            :is-show-fileList="false"
            :hasFileList="excList"
        >
        </exc-upload>
      </a-col>
    </a-row>
    <a-row>
      <a-col span="24" style="padding-top: 30px">
        <a-table
            :columns="columns"
            :data-source="data_list"
            :rowKey="(record, index) => index"
            :pagination="false"
            :loading="loading"
            bordered
        >
          <div slot="operation" slot-scope="text, record">
            <a :href="record.attachUrl" download="blog">下载</a>
            <a-divider type="vertical"/>
            <a @click="handleDelete(record)">删除</a>
          </div>
        </a-table>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>

import ExcUpload from "@/components/exc-upload.vue";
import _https from "@/api/workOrder";

export default {
  data() {
    return {
      columns: [
        {
          title: "模板名",
          dataIndex: "attachName",
          align: "center",
        },
        {
          title: "上传人",
          dataIndex: "creatorName",
          align: "center",
        },
        {
          title: "部门",
          dataIndex: "department",
          align: "center",
        },
        {
          title: "上传时间",
          dataIndex: "ctime",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {customRender: "operation"},
        },
      ],
      data_list: [],
      excList: [],
      loading: false,
    };
  },
  created() {
    this.getExcelModulelist();
  },
  components: {ExcUpload},
  methods: {
    getExcelModulelist() {
      _https.getExcelModulelist()
          .then((res) => {
            if(res.code == 200) {
              this.data_list = res.data || [];
            } else {
              this.$message.error(res.message || "获取数据失败");
            }
          });
    },
    handleUrl(val, type) {
      const {size, name, uid, xhr} = val[0];
      let data = {
        attachUrl: xhr,
        attachName: name,
        attachSize: size,
      };
      _https.uploadeExcelModule(data).then(res => {
        if(res.code == 200) {
          this.$message.success("上传成功");
          this.getExcelModulelist();
          this.excList = [];
        } else {
          this.$message.error(res.message || "删除失败");
        }
      });
    },
    handleDelete(record) {
      const that = this;
      that.$confirm({
        content: "确定要删除该模板吗？",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _https.deteleExcelModule(record.id).then(res => {
            if(res.code == 200) {
              that.$message.success("删除成功");
              that.getExcelModulelist();
            } else {
              that.$message.error("删除失败");
            }
          });
        },
      });
    }, //删除
  },
};
</script>

<style lang="less" scoped>

</style>
